<template>
  <ul>
    <li class="progress" :style="{ background: gradient }">
      <div class="name">{{ name }}</div>
      <div
        class="status"
        :class="{ fail: target > value }"
        v-if="target > value"
      >
        FAIL
      </div>
      <div class="status" :class="{ fail: target > value }" v-else>PASS</div>
      <div class="description">{{ description }}</div>
      <div class="target">{{ target }}%</div>
      <!-- <div class="progress-done" :style="{ width: value + '%' }">
        {{ value }}
      </div> -->
      <div class="progress-done" :class="{ fail: target > value }">
        {{ value }}%
      </div>
      <div class="marker" :style="{ width: value + '%' }"></div>
      <!-- <div class="bracket" :style="{ width: bracketSpan + '%' }"></div> -->
    </li>
  </ul>
</template>

<script>
import { ref, defineComponent } from "vue"

export default defineComponent({
  name: "Legend2",
  props: {
    name: { type: String, default: "NAME" },
    max: { type: Number, default: 100 },
    step: { type: Number, default: 20 },
    value: { type: Number, default: 80 },
    target: { type: String, default: "10" },
    description: String,
    bracketSpan: { type: Number, default: 10 },
    gradientColors: {
      type: Array,
      default: ["#0C2C84", "#1E84BA", "#60C1BF"],
    },
  },
  setup(props) {
    const gradientColors = props.gradientColors

    function getGradient(colors) {
      const gradients = []
      let gradientStop = 0
      let visualStop = 0
      const stepSize = 100 / colors.length

      colors.forEach((color, index) => {
        // Keep current gradientStop for the start of the block
        if (index === 0) {
          visualStop = 0
        } else {
          visualStop = gradientStop
        }
        gradients.push(`${color} ${visualStop}%`)

        // Increment the gradientStop for the end of the block
        gradientStop += stepSize
        if (index === colors.length - 1) {
          visualStop = 100
        } else {
          visualStop = gradientStop
        }
        gradients.push(`${color} ${visualStop}%`)
      })

      return `linear-gradient(to right, ${gradients.join(",")})`
    }

    const gradient = getGradient(gradientColors)
    // console.log(gradient)
    return { gradient }
  },
})
</script>



<style lang="scss" scoped>
* {
  --width: 10%;
  --gradient: linear-gradient(
    to right,
    #0c2c84,
    #0c2c84 20%,
    #1e84ba 20%,
    #1e84ba 40%,
    #60c1bf 40%,
    #60c1bf 60%,
    #d0ecb3 60%,
    #d0ecb3 80%,
    #ffffd9 80%,
    #ffffd9
  );
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 40px 0;
  .progress {
    height: 2rem;
    width: 70%;
    margin: 0 auto;
    background: var(--gradient);
    // border-radius: 20px;
    position: relative;
    border-bottom: 1px solid rgba(#fff, 0.45);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 30%);

    .name {
      position: absolute;
      left: -22%;
      top: -2rem;
      font-size: 0.8rem;
    }
    .status {
      font-size: 0.8rem;
      position: absolute;
      right: -22%;
      top: -2rem;
      color: var(--color-dark-green);
      &.fail {
        color: var(--color-red);
      }
    }

    .description {
      position: absolute;
      top: -2rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .target {
      font-size: 0.9rem;
      position: absolute;
      left: -22%;
      font-size: 1.1rem;
      position: absolute;
      bottom: 0.7rem;
      &::before {
        position: absolute;
        bottom: -0.7rem;
        font-size: 0.7rem;
        line-height: 0.7rem;
        font-weight: 500;
        content: "TARGET";
      }
    }

    .progress-done {
      font-size: 1.1rem;
      position: absolute;
      right: -22%;
      bottom: 0.7rem;
      color: var(--color-dark-green);
      &.fail {
        color: var(--color-red);
      }
      &::after {
        position: absolute;
        bottom: -0.7rem;
        right: 0;
        font-size: 0.7rem;
        color: var(--color-text);
        line-height: 0.7rem;
        font-weight: 500;
        content: "SCORE";
      }
    }
    .marker {
      height: 200%;
      position: absolute;
      bottom: -50%;
      text-align: right;
      transition: 1s ease;

      // border-right: 3px solid black;
    }
    .bracket {
      height: 120%;
      position: absolute;
      bottom: -10%;
      right: 0;
      text-align: right;
      transition: 1s ease;
      border-radius: 20px;
      border: 2px solid black;

      &::after {
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 9px;
        font-weight: 200;
        content: "VALID";
      }
    }
  }
}
</style>
