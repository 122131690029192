<template>
  <ul>
    <li class="close" @click="$emit('close')">✕&nbsp;&nbsp;&nbsp; CLOSE</li>
    <li
      v-for="(item, id, index) in cats"
      :key="id"
      :class="{
        selected:
          categories.selected == undefined
            ? index == 0
            : index == categories.selected,
        failed: item.fail,
      }"
      @click="
        selectedID == index ? (selectedID = -1) : (selectedID = index),
          $emit('select', [item.metric, index])
      "
    >
      <h3>{{ id }}</h3>
      <p>{{ item.description }}</p>
    </li>
  </ul>
</template>

<script>
import { ref, defineComponent, watch, toRefs, computed } from "vue"

export default defineComponent({
  name: "SubMenu",
  props: {
    categories: {},
  },

  setup(props) {
    const selectedID = ref(0)

    const cats = computed(() => {
      const filtered = Object.keys(props.categories)
        .filter((key) => key != "selected" && key != "icon")
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: props.categories[key],
          }
        }, {})
      return filtered
    })
    return { selectedID, cats }
  },
})
</script>

<style lang="scss" scoped>
ul {
  --max-width: 280px;
  --max-width-selected: 430px;
  position: absolute;
  bottom: 100%;
  right: 20%;
  display: none;
  text-align: left;
  list-style: none;
  @media (max-width: 600px) {
    right: 0;
    --max-width: 180px;
    --max-width-selected: 230px;
  }
  li {
    float: right;
    clear: right;
    display: block;
    cursor: pointer;
    width: var(--max-width);
    background-color: white;
    padding: 5px 25px 5px 10px;
    margin: 4px 0;
    box-shadow: var(--shadow-medium);
    // border-bottom: 4px solid var(--color-blue);
    border-bottom: 4px solid var(--color-bg-dark);
    transition: all 0.3s;
    @media (max-width: 600px) {
      margin: 0;
      padding: 6px 25px 4px 20px;
    }

    &.close {
      border-radius: 6px;
      width: fit-content;
      padding: 10px 25px;
      border-width: 0px;
      color: #fff;
      background: var(--color-bg-dark);
      box-shadow: var(--shadow-dark);
      transition: transform 0.2s;

      &:hover {
        transform: scale(0.98);
      }
      &:active {
        transform: scale(0.92);
      }
    }

    &.selected {
      color: white;
      // background-color: var(--color-blue);
      background-color: var(--color-bg-dark);
      padding-right: 30px;
      padding-left: 30px;
      width: var(--max-width-selected);
      h3 {
        font-size: 1rem;
      }
      p {
        font-size: 1rem;

        display: block;
        color: white;
        @media (max-width: 600px) {
          display: none;
        }
      }
      &.failed {
        background-color: var(--color-red);
      }
    }
    &.failed {
      border-bottom-color: var(--color-red);
    }
    h3 {
      font-size: 1rem;
      font-weight: 900;
      line-height: 2rem;
      letter-spacing: 0.01rem;
      margin: 0;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
    p {
      display: none;
      // font-weight: 400;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin: 2px 0;
      color: var(--color-text-light);
    }
  }
}
</style>