<template>
  <div class="progress" :style="{ background: gradient }">
    <div class="name">{{ name }}</div>
    <div class="progress-done" :style="{ width: value + '%' }">
      {{ value }}%
    </div>
    <div class="marker" :style="{ width: value + '%' }"></div>
    <div class="bracket" :style="{ width: bracketSpan + '%' }"></div>
  </div>
</template>

<script>
import { ref, defineComponent } from "vue"

export default defineComponent({
  name: "Legend",
  props: {
    name: { type: String, default: "NAME" },
    max: { type: Number, default: 100 },
    step: { type: Number, default: 20 },
    value: { type: Number, default: 80 },
    bracketSpan: { type: Number, default: 10 },
    gradientColors: {
      type: Array,
      default: ["#0C2C84", "#1E84BA", "#60C1BF"],
    },
  },
  setup(props) {
    const gradientColors = props.gradientColors

    function getGradient(colors) {
      const gradients = []
      let gradientStop = 0
      let visualStop = 0
      const stepSize = 100 / colors.length

      colors.forEach((color, index) => {
        // Keep current gradientStop for the start of the block
        if (index === 0) {
          visualStop = 0
        } else {
          visualStop = gradientStop
        }
        gradients.push(`${color} ${visualStop}%`)

        // Increment the gradientStop for the end of the block
        gradientStop += stepSize
        if (index === colors.length - 1) {
          visualStop = 100
        } else {
          visualStop = gradientStop
        }
        gradients.push(`${color} ${visualStop}%`)
      })

      return `linear-gradient(to right, ${gradients.join(",")})`
    }

    const gradient = getGradient(gradientColors)
    // console.log(gradient)
    return { gradient }
  },
})
</script>



<style lang="scss" scoped>
* {
  --width: 10%;
  --gradient: linear-gradient(
    to right,
    #0c2c84,
    #0c2c84 20%,
    #1e84ba 20%,
    #1e84ba 40%,
    #60c1bf 40%,
    #60c1bf 60%,
    #d0ecb3 60%,
    #d0ecb3 80%,
    #ffffd9 80%,
    #ffffd9
  );
}

.progress {
  height: 12px;
  width: 100%;

  background: var(--gradient);
  border-radius: 20px;
  position: relative;
  border-bottom: 1px solid rgba(#fff, 0.45);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 30%);

  .name {
    position: absolute;
    left: 0;
    bottom: 1rem;
    font-size: 1rem;
  }

  .progress-done {
    height: 200%;
    width: 80%;
    position: absolute;
    bottom: 120%;
    text-align: right;
    transition: 1s ease;
    font-size: 13px;
    font-weight: 900;
  }
  .marker {
    height: 200%;
    position: absolute;
    bottom: -50%;
    text-align: right;
    transition: 1s ease;
    // border-right: 3px solid black;

    &::after {
      position: absolute;
      right: 0;
      top: 18%;
      content: "";
      width: 10px;
      height: 10px;
      border: 3px solid white;
      border-radius: 9999px;
      box-shadow: var(--shadow-dark);
    }
  }
  .bracket {
    height: 160%;
    position: absolute;
    bottom: -80%;
    right: 0;
    text-align: right;
    transition: 1s ease;
    border-bottom: 4px solid var(--color-dark-green);
    border-left: 1px solid var(--color-dark-green);
    border-right: 1px solid var(--color-dark-green);

    &::after {
      position: absolute;
      right: 0;
      bottom: -1.5rem;
      font-size: 0.65rem;
      font-weight: 700;
      color: var(--color-dark-green);
      content: "TARGET";
    }
  }
}
</style>
