<template>
  <div class="blur"></div>
  <div class="container">
    <div class="splash">
      <ThreeSun />
      <div class="info">Loading Daylight Model</div>
      <div class="loadingbar">
        <div class="progress" :style="{ width: progress + '%' }">
          <div class="number" v-if="progress > 2">{{ progress }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import ThreeSun from "./ThreeSun.vue"

export default defineComponent({
  components: {
    ThreeSun,
  },
  props: { progress: Number },
  setup() {},
})
</script>
<style lang="scss" scoped>
.blur {
  position: absolute;
  backdrop-filter: blur(5px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .splash {
    width: 50%;
    height: 50%;

    @media (max-width: 800px) {
      width: 100%;
      height: 50%;
    }
    // background-color: red;

    .info {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .loadingbar {
      background-color: white;
      margin-left: 5%;
      width: 90%;
      border-radius: 10px;
      height: 20px;
      box-shadow: var(--shadow-dark);

      .progress {
        height: 100%;
        min-width: 20px;
        border-radius: 10px;
        background: linear-gradient(
          90deg,
          var(--color-light-green) 0%,
          var(--color-dark-green) 100%
        );
        text-align: right;

        transition: width 200ms;

        .number {
          height: 100%;
          font-size: 70%;
          transform: translateY(-5%);
          margin-right: 10px;
          font-weight: 900;
          color: white;
        }
      }
    }
  }
}
</style>
