
import { defineComponent } from "vue"
import ThreeSun from "./ThreeSun.vue"

export default defineComponent({
  components: {
    ThreeSun,
  },
  props: { progress: Number },
  setup() {},
})
