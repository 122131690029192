<script>
/* eslint-disable no-unused-vars */
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue"
import { useStore } from "vuex"
import useViewer from "../composables/useViewer.js"
import { useRoute } from "vue-router"

import chroma from "chroma-js"

import validation from "../assets/json/validation.json"
import _metrics from "../assets/json/metrics.json"
import presets from "../assets/json/presets.json"

import Slider from "../components/Slider"
import Panel from "../components/Panel"
import SubMenu from "../components/SubMenu"
import Icon from "../components/Icon"
import Button from "../components/Button"
import Splash from "../components/splash"
import Legend from "../components/Legend"
import Legend2 from "../components/Legend2"
import { usePerms } from "../logic/usePerms"

let colorScale = {
  BlYlGr: chroma
    .scale(["#0C2C84", "#1E84BA", "#60C1BF", "#D0ECB3", "#FFFFD9"])
    .domain([0, 0.02])
    .classes(5),
  plasma: chroma
    .scale(["#2F0087", "#9F0F9C", "#E67953", "#E4FA15"])
    .domain([0, 0.1])
    .classes(4),
  // plasma: chroma.scale(['#2F0087','#56009D','#7C00A5','#9F0F9C','#BD3386','#D4566C','#E67953','#F1A039','#F1A039','#E4FA15']).domain([0,0.1]).classes(4),
  UDI: chroma
    .scale(["#480054", "#39AEC3", "#F3E91C", "#E73363"])
    .domain([0, 0.1])
    .classes(4),
  DGP: chroma
    .scale(["#C7C7C7", "#FFDB40", "#F79F5C", "#F7515C"])
    .domain([0, 0.05])
    .classes(4),
  ASE: chroma.scale(["#E0E0E0", "#DE2E17"]).domain([0, 0.25]).classes(2),
  pink: chroma
    .scale([
      "#2F0087",
      "#56009D",
      "#7C00A5",
      "#9F0F9C",
      "#BD3386",
      "#D4566C",
      "#E67953",
      "#F1A039",
      "#F1A039",
      "#E4FA15",
    ])
    .domain([0, 0.02])
    .classes(5),
  valid: chroma.scale(["#f05f60", "#13B676"]).domain([0.5, 0.5]).classes(2), //chroma.scale(["#de74ad", "#80bb46"]).domain([0.5, 0.5]).classes(2),
}

export default defineComponent({
  components: {
    Slider,
    Panel,
    SubMenu,
    Icon,
    Button,
    Splash,
    Legend,
    Legend2,
  },
  setup(props) {
    const { hasPerm, checkToken } = usePerms()

    const canvas = ref(null)
    const dataReady = ref(false)
    const store = useStore()
    const viewer = useViewer()
    // const validations = reactive(validation)
    const metrics = reactive(_metrics)
    const pset = reactive(presets)
    const route = useRoute()

    const {
      singleClick,
      dblClick,
      pointerclick,
      savemoseclick,
      drawResults,
      changeRoom,
      downloadprogress,
      drawmode,
      floors,
      iterations,
      materials,
      northrot,
      projectInfo,
      resultsData,
      roomNames,
      roomNummer,
      selectedData,
      selectedfloor,
      selStandard,
      transparentMode,
      sortrooms,
      rankedrooms,
      rank,
      roomNamesnotsim,
      validations,
      projectConstants,
      keyP,
      prediction,
      viewMode,
      img,
      saveScreenshot,
      saveTraingdata,
      getPredictions,
      createExcel,
    } = viewer

    let jobId = ref(undefined)
    onMounted(async () => {
      if (route.query.jobId != undefined) {
        jobId.value = route.query.jobId.toString()
      }

      await viewer.init(canvas, { lights: true })
      await viewer.loadModel(jobId.value)
      setTimeout(() => {
        viewer.onchange()
        viewer.drawResults()
        dataReady.value = true
      }, 500)
      //   animate()
    })

    const displayInfoPanel = ref(true)
    const displayRoomPanel = ref(false)
    const showSubmenu = ref(false)
    const getColorArray = (dataName) => {
      let pset = presets[dataName]
      if (pset == undefined) {
        pset = presets["default"]
      }
      const cs = colorScale[pset.colorScale]
      if (pset.domain != undefined) {
        cs.domain(pset.domain)
      }
      if (pset.classes != undefined) {
        cs.classes(pset.classes)
      }
      // console.log(cs.domain())
      // console.log(cs.classes().slice(0, -1))
      const colorArray = cs
        .classes()
        .slice(0, -1)
        .map((v) => {
          return cs(v).hex()
        })
      return { colors: colorArray, values: cs.classes() }
    }

    const closeMenu = () => {
      setTimeout(() => {
        showSubmenu.value = false
      }, 2000)
    }
    return {
      canvas,
      store,
      singleClick,
      dblClick,
      pointerclick,
      savemoseclick,
      getColorArray,
      drawResults,
      changeRoom,
      iterations,
      projectInfo,
      resultsData,
      selectedData,
      selectedfloor,
      transparentMode,
      floors,
      dataReady,
      northrot,
      downloadprogress,
      displayInfoPanel,
      displayRoomPanel,
      drawmode,
      roomNummer,
      validations,
      metrics,
      showSubmenu,
      selStandard,
      pset,
      roomNames,
      materials,
      jobId,
      sortrooms,
      rankedrooms,
      rank,
      closeMenu,
      roomNamesnotsim,
      projectConstants,
      keyP,
      prediction,
      viewMode,
      saveScreenshot,
      img,
      hasPerm,
      saveTraingdata,
      getPredictions,
      createExcel
    }
  },
})
</script>

<template>
  <div class="container">
    <!-- @dblclick="dblClick"
      @click="singleClick" -->
    <div class="canvas" @pointerup="pointerclick" @pointerdown="savemoseclick" ref="canvas"></div>

    <header class="topbar">
      <div class="ot-projectName">
        <div class="title">
          {{ projectInfo.name }}
        </div>
        <div class="iterations" v-if="iterations.length > 1">
          <div v-for="(job, i) in iterations" :key="job" :class="{ current: job.id == jobId }" class="dot"
            :title="job.name">
            <router-link :to="'/viewer?jobId=' + job.id">
              {{ i + 1 }}
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <div class="storeys">
      <div class="northArrow circle" :style="{ '-webkit-transform': 'rotate(' + northrot + 'deg)' }">
        N
      </div>
      <div class="roundBtn circle plus" @click="
        selectedfloor >= floors.length - 1
          ? (selectedfloor = 0)
          : (selectedfloor += 1)
      "></div>
      <div class="storey circle" v-if="floors.length > 0">
        {{ floors[selectedfloor].toFixed(1) }}m
      </div>
      <div class="roundBtn circle minus" @click="
        selectedfloor < 1
          ? (selectedfloor = floors.length - 1)
          : (selectedfloor -= 1)
      "></div>
      <div class="tabSide">
        <div class="actionBtn tooltip left" title="Transparency" @click="(e) => e.target.classList.toggle('active')">
          <Icon @click="transparentMode = !transparentMode">domain_disabled</Icon>
        </div>
        <!-- <div
          class="actionBtn tooltip left"
          title="Material reflectivity"
          @click="(e) => e.target.classList.toggle('active')"
        > -->
        <!-- <Icon>line_weight</Icon>
          <svg
            @click="materialMode = !materialMode"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 80"
            x="0px"
            y="0px"
          >
            <g>
              <circle cx="17" cy="17" r="7" />
              <path d="M17,9a1,1,0,0,0,1-1V4a1,1,0,0,0-2,0V8A1,1,0,0,0,17,9Z" />
              <path
                d="M17,25a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V26A1,1,0,0,0,17,25Z"
              />
              <path d="M9,17a1,1,0,0,0-1-1H4a1,1,0,0,0,0,2H8A1,1,0,0,0,9,17Z" />
              <path
                d="M25,17a1,1,0,0,0,1,1h4a1,1,0,0,0,0-2H26A1,1,0,0,0,25,17Z"
              />
              <path
                d="M9.929,11.343a1,1,0,0,0,1.414-1.414L8.515,7.1A1,1,0,1,0,7.1,8.515Z"
              />
              <path
                d="M24.071,22.657a1,1,0,0,0-1.414,1.414L25.485,26.9A1,1,0,0,0,26.9,25.485Z"
              />
              <path
                d="M9.929,22.657,7.1,25.485A1,1,0,1,0,8.515,26.9l2.828-2.828a1,1,0,0,0-1.414-1.414Z"
              />
              <path
                d="M23.364,11.636a.993.993,0,0,0,.707-.293L26.9,8.515A1,1,0,1,0,25.485,7.1L22.657,9.929a1,1,0,0,0,.707,1.707Z"
              />
              <path
                d="M60,3H54a1,1,0,0,0-1,1V30.515l-3.611-1.464a1,1,0,1,0-.752,1.853L52.318,32.4l-4.06,4.614a1,1,0,0,0,1.5,1.321L53,34.65V60a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,60,3Z"
              />
              <path
                d="M45.275,40.4l-5.027,5.714a1,1,0,0,0,1.5,1.321l5.027-5.714a1,1,0,0,0-1.5-1.321Z"
              />
              <path
                d="M40.619,27.653l5.014,2.033a1,1,0,1,0,.752-1.853L41.371,25.8a1,1,0,0,0-.752,1.854Z"
              />
              <path
                d="M38.424,24.6,33.4,22.567a1,1,0,0,0-.752,1.853l5.024,2.038a1.014,1.014,0,0,0,.376.073,1,1,0,0,0,.376-1.927Z"
              />
              <path
                d="M30.376,21.34l-4.967-2.014a1,1,0,0,0-.752,1.854l4.967,2.014a1,1,0,0,0,.752-1.854Z"
              />
              <path
                d="M38,56H34.319l4.44-5.167a1,1,0,0,0-1.518-1.3L33,54.465V51a1,1,0,0,0-2,0v6a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2Z"
              />
            </g>
          </svg>
        </div> -->
        <!-- <div v-if="store.state.toggle.dev" class="actionBtn">
          <Icon>view_in_ar</Icon>
        </div> -->
      </div>
      <!-- <div class="simulationSide">
        <div class="tooltip left" title="Buy new Simulation">
          <Icon>add_shopping_cart</Icon>
        </div>
      </div> -->
    </div>
    <footer v-if="dataReady">
      <nav class="tabInfo">
        <Icon class="iconholder" @click="displayInfoPanel = !displayInfoPanel">navigate_next</Icon>
        <span class="placeholder" :class="{ active: displayInfoPanel }" @click="
          ;(displayInfoPanel = !displayInfoPanel), (displayRoomPanel = false)
        ">
          <Icon>description</Icon> {{ $t("viewer.project") }}
        </span>
        <span class="placeholder" :class="{ active: displayRoomPanel }" @click="
          ;(displayRoomPanel = !displayRoomPanel), (displayInfoPanel = false)
        ">
          <img src="..\assets\images\icon_room.svg" />

          {{ $t("viewer.room") }}</span>

        <div class="white-overlay" :class="{ 'is-open': displayInfoPanel || displayRoomPanel }"></div>
        <div class="infoPanel" :class="{ 'is-open': displayInfoPanel }">
          <div class="projectHeader darkBg">
            <div class="close" @click="displayInfoPanel = !displayInfoPanel">
              ✕
            </div>
            <div class="projectLocation">
              <Icon>place</Icon>
              <p>{{ projectInfo.longlat + " " + projectInfo.location }}</p>
            </div>
            <h3>{{ projectInfo.name }}</h3>
            <div class="projectInfo">
              <p v-if="projectInfo.number">{{ projectInfo.number }}</p>
            </div>
            <img v-if="hasPerm('system.admin')" style="max-width: 100%; margin: 0 auto" :src="img" @click="
              (e) => {
                saveScreenshot(e, 1600, 900)
              }
            " />
          </div>

          <ul class="infoBoxes">
            <!-- <li class="element">
              <span>{{ $t("viewer.iterations") }}</span>
              <ul class="iterations">
                <li></li>
                <li></li>
              </ul>
            </li> -->
            <li class="element">
              <span>{{ selStandard }} PASS</span>
              <p class="score" v-if="resultsData[0] && resultsData[0].valids[selStandard]">
                {{ Math.round(projectInfo.valids[selStandard] * 100) }}%
              </p>
              <!-- <Icon class="increase">north_east</Icon> -->
              <!-- <strong>+3%</strong> since previous -->
            </li>
            <li class="element">
              <span>PASS/FAIL</span>
              <p class="score" v-if="resultsData[0] && resultsData[0].valids[selStandard]">
                {{
                resultsData.filter((room) => {
                return room.valids[selStandard].points > 0
                }).length
                }}
                /
                {{
                resultsData.filter((room) => {
                return room.valids[selStandard].points == 0
                }).length
                }}
              </p>
              <!-- <p>{{ Math.round(projectInfo.valids[selStandard] * 100) }}%</p> -->
              <!-- <Icon class="increase">north_east</Icon> -->
              <!-- <strong>+3%</strong> since previous -->
            </li>
          </ul>

          <div class="content-wrapper">
            <h4>
              {{ $t("viewer.rooms") }}
              <!--<span>{{ resultsData.length }} - rooms</span>-->
            </h4>
            <!-- <p>Rooms defined as regularly occupied</p> -->
            <span class="simulationState">{{ $t("viewer.simulated") }}</span>
            <span class="simulationState notSimulated">{{
            $t("viewer.notSimulated")
            }}</span>
            <ul class="tagsList">
              <li v-for="(type, id) in roomNames" :key="id">{{ type }}</li>
              <li class="notSimulated" v-for="(type, id) in roomNamesnotsim" :key="1 + id">
                {{ type }}
              </li>
            </ul>
            <h4>
              {{ $t("viewer.opaqueMaterials") }}
              <span>{{ $t("viewer.reflectivity") }}</span>
            </h4>
            <ul class="valueList">
              <li v-for="(type, id) in materials.filter((m) => {
                return !(
                  m.name.toLowerCase().includes('glass') ||
                  m.name.toLowerCase().includes('window')
                )
              })" :key="id">
                {{ type.name }}
                <span class="value-color" :style="{
                  'background-color': `rgb(${type.LRLT * 2.55}, ${
                    type.LRLT * 2.55
                  }, ${type.LRLT * 2.55})`,
                }"></span>
                <span class="value">{{ type.LRLT }}</span>
              </li>
            </ul>
            <h4>
              {{ $t("viewer.transparentMaterials") }}
              <span>{{ $t("viewer.transmission") }}</span>
            </h4>
            <ul class="valueList">
              <li v-for="(type, id) in materials.filter((m) => {
                return (
                  m.name.toLowerCase().includes('glass') ||
                  m.name.toLowerCase().includes('window')
                )
              })" :key="id">
                {{ type.name }}
                <span class="value-color" :style="{
                  'background-color': `rgb(${type.LRLT * 2.55}, ${
                    type.LRLT * 2.55
                  }, ${type.LRLT * 2.55})`,
                }"></span>
                <span class="value">{{ type.LRLT }}</span>
              </li>
            </ul>
            <!-- <Button
              bgColor="var(--color-dark-green)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px; width: 100%; margin-top: 20px"
              ><Icon>download</Icon> {{ $t("viewer.downloadReport") }}</Button
            > -->
            <Button bgColor="var(--color-bg)" txtColor="var(--color-text-lighter)" shadow="var(--shadow-medium)"
              style="border-radius: 12px; width: 100%; margin-top: 20px" @click="createExcel">

              <Icon>download</Icon> {{ $t("viewer.downloadReport") }}
            </Button>
          </div>
        </div>

        <div class="infoPanel" :class="{ 'is-open': displayRoomPanel }">
          <div class="projectHeader darkBg">
            <div class="close" @click="displayRoomPanel = !displayRoomPanel">
              ✕
            </div>
            <div class="projectLocation">
              <Icon>info</Icon>
              <p>{{ $t("viewer.roomInfo") }}</p>
            </div>
            <h3>{{ resultsData[roomNummer].names }}</h3>
            <div class="projectInfo">
              <p>{{ resultsData[roomNummer].area.toFixed(1) }} m²</p>
            </div>
          </div>

          <ul class="infoBoxes">
            <li v-for="(type, id) in validations" :key="id" class="validation">
              <span>{{ type.metric }}</span>
              <p></p>
              <Icon v-if="resultsData[roomNummer].valids[id].points > 0" class="increase">check</Icon>
              <Icon v-else class="decrease">remove</Icon>
            </li>
          </ul>

          <div v-if="store.state.toggle.dev">
            <select v-model="resultsData[roomNummer].recomend" name="" id="" style="background-color: white"
              @change="resultsData[roomNummer].recomendchange = true">
              <option :value="undefined">NoPrediction</option>
              <option :value="prediction" :key="prediction" v-for="prediction in [
                'V-BAD DEEP NORTH',
                'V-BAD DEEP',
                'BAD SIMPLE REMAKE NORTH',
                'BAD SIMPLE REMAKE',
                'BALCONIES',
                'MORE WINDOWS FOR UNIFORMITY',
                'LRLT &/OR MORE WINDOWS',
                'EASY FIX WITH LRLT',
                'UDI EXCESS HIGH',
              ]">
                {{ prediction }}
              </option>
            </select>
            {{ resultsData[roomNummer].recomendchange }}
            <p v-if="resultsData[roomNummer].recomend">
              {{ $t(`recommendations.${resultsData[roomNummer].recomend}`) }}
            </p>

            <button style="margin-left: 5px; width: 20px" @click="saveTraingdata()">
              train
            </button>
            <button style="margin-left: 5px; width: 20px" @click="getPredictions()">
              predict
            </button>
          </div>
          <div class="content-wrapper">
            <div v-if="drawmode == 0">
              <div v-for="(method, mi) in validations[selStandard].methods" :key="method">
                <h4>{{ method.name }}</h4>
                <ul class="legends">
                  <li v-for="(req, ri) in method.req" :key="req" class="element">
                    <Legend2 :name="req.metric" :value="
                      req.value == 'spatial'
                        ? Number(
                            (
                              resultsData[roomNummer].valids[selStandard]
                                .methods[mi][ri] * pset[req.metric].scale
                            ).toFixed(0)
                          )
                        : Number(
                            (
                              resultsData[roomNummer].valids[selStandard]
                                .methods[mi][ri] * pset[req.metric].scale
                            ).toFixed(pset[req.metric].desimal)
                          )
                    " :bracketSpan="15" :target="
                      req.value == 'spatial'
                        ? (req['min%'] * pset[req.metric].scale).toFixed(0)
                        : (req['cMin'] * pset[req.metric].scale).toFixed(
                            pset[req.metric].desimal
                          )
                    " :description="
                      req.value == 'spatial'
                        ? `${req.metric} ${(
                            req['cMin'] * pset[req.metric].scale
                          ).toFixed(pset[req.metric].desimal)}% in ${(
                            req['min%'] * 100
                          ).toFixed(0)}% of the room`
                        : req.value == 'min'
                        ?`${req.metric}${req.value} of room >= ${(
                            req['cMin'] * pset[req.metric].scale
                          ).toFixed(pset[req.metric].desimal)}%`
                        : `Room ${req.value} of ${req.metric} ${(
                            req['cMin'] * pset[req.metric].scale
                          ).toFixed(pset[req.metric].desimal)}%`
                    " :gradientColors="getColorArray(req.metric).colors" />
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="emptySelection">
              <Icon>grain</Icon><br />{{ $t("viewer.noValidation") }}
            </div>
            <!-- <div v-for="metrics in req.metric" >{{ resultsData }}</div> -->
          </div>
        </div>
      </nav>

      <nav class="tabControls">
        <Slider v-if="drawmode == 0" :max="resultsData.length" :value="rankedrooms.indexOf(roomNummer)"
          :gradientColors="['var(--color-red)', 'var(--color-dark-green)']"
          :gradientSteps="[100 - projectInfo.valids[selStandard] * 100]" :isFail="
            rankedrooms.indexOf(roomNummer) / resultsData.length <
            1 - projectInfo.valids[selStandard]
          " @roomChange="
            ;(rank = Number($event)), (roomNummer = rankedrooms[Number($event)])
          " />

        <Slider v-else :max="'%'" :value="0" :isDisabled="true" :gradientColors="getColorArray(selectedData).colors"
          :gradientValues="getColorArray(selectedData).values" :name="selectedData" />
        <!-- :name="selectedData" -->
        <!-- :style="{ 'font-size': 0 + 'px', color: 'red' }" -->

        <div class="roomNav">
          <div class="previous noselect" :class="{ disabled: rank == 0 }" @click="
            ;(viewMode = 1),
              rank < 1 ? (rank = 0) : (rank -= 1),
              (roomNummer = rankedrooms[rank]),
              changeRoom(roomNummer)
          ">
            <Icon>skip_previous</Icon>
          </div>
          <div class="roomInfo" v-if="resultsData.length > 0" @click=";(viewMode = 1), changeRoom(roomNummer)">
            <p>{{ resultsData[roomNummer].names }}</p>
            <span>{{ resultsData[roomNummer].area.toFixed(1) }} m²</span>
            <!-- <p>{{ resultsData[roomNummer].IFCname }}</p> -->
          </div>
          <!--<div
            v-if="resultsData[roomNummer] != undefined"
            class="roomInfo"
            :class="{ valid: roomNummer > 10 }"
          >
            {{ resultsData[roomNummer].names }} <br />512.22.369 <br />{{
              resultsData[roomNummer].area.toFixed(1)
            }}m2
          </div>-->

          <div class="next noselect" @click="
            ;(viewMode = 1),
              (rank += 1),
              (roomNummer = rankedrooms[rank]),
              changeRoom(roomNummer)
          ">
            <Icon>skip_next</Icon>
          </div>
        </div>
      </nav>

      <nav class="tabBar">
        <ul class="items">
          <li v-for="(type, id) in validations" :key="id" class="item" :class="{ selected: selStandard == id }" @click="
            ;(drawmode = 0),
              selStandard == id
                ? (showSubmenu = !showSubmenu)
                : (showSubmenu = true),
              (selStandard = id),
              sortrooms(),
              drawResults()
          ">
            <a>
              <div></div>
              <strong>{{ id }}</strong>
            </a>
          </li>
          <li v-for="(type, id) in metrics" :key="id" class="item" :class="{ selected: selStandard == id }" @click="
            ;(drawmode = 1),
              selStandard == id
                ? (showSubmenu = !showSubmenu)
                : (showSubmenu = true),
              (selStandard = id),
              (selectedData =
                metrics[selStandard][
                  Object.keys(metrics[selStandard])[
                    metrics[selStandard].selected
                  ]
                ].metric),
              drawResults()
          ">
            <a>
              <strong>
                <Icon>{{ type.icon }}</Icon>
              </strong></a>
          </li>
        </ul>
        <SubMenu v-if="showSubmenu && drawmode == 1" @select="
          ;(selectedData = $event[0]),
            (metrics[selStandard].selected = $event[1])
        " @close="showSubmenu = false" @mouseleave="closeMenu()" class="subMenu" :categories="metrics[selStandard]" />
        <SubMenu v-if="showSubmenu && drawmode == 0" @select="selectedData = $event[0]" class="subMenu"
          @close="showSubmenu = false" @mouseleave="closeMenu()"
          :categories="{ [selStandard]: validations[selStandard] }" />

        <div class="selectionPanel"></div>
      </nav>
    </footer>
    <Splash v-if="!dataReady" :progress="downloadprogress" />
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: absolute;
  inset: 0;
}

.canvas {
  position: absolute;
  inset: 0;
}

@import "../css/viewer.scss";

.container {
  background: var(--color-bg-viewer2);
}
</style>