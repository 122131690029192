<template>
  <section>
    <div>
      <Legend :name="'cDA'" :value="80" :bracketSpan="45" />
      <Legend
        :name="'UDI'"
        :value="60"
        :bracketSpan="15"
        :gradientColors="['#0C2C84', '#1E84BA']"
      />
      <Legend2
        :name="'sDA'"
        :value="60"
        :bracketSpan="15"
        :target="'5'"
        :gradientColors="['#0C2C84', '#1E84BA']"
      />
    </div>

    <div>
      <input type="checkbox" id="pointValues" />
      <label for="pointValues">
        <span class="checkmark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
        <span class="textValue">Display point grid values</span>
      </label>
    </div>
  </section>
</template>

<script>
import Legend from "./Legend"
import Legend2 from "./Legend2"

export default {
  name: "Panel",
  components: {
    Legend,
    Legend2,
  },
  props: {
    max: { type: Number, default: 50 },
    value: { type: Number, default: 0 },
  },
  computed: {
    style() {
      return {
        "--min": 0,
        "--max": this.max,
        "--step": 1,
        "--value": this.value,
        "--text-value": '"' + this.value.toString() + '"',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  display: none;

  &[type="checkbox"]:checked + label span:first-of-type {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    color: #fff;
  }
  &[type="checkbox"]:checked + label span:nth-of-type(2) {
    color: var(--color-text);
  }
}

label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 4rem;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 0.25rem;
  &:hover {
    background-color: rgba(243, 244, 246, 1);
  }
  .checkmark {
    width: 2.25rem;
    color: transparent;
    height: 2.25rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 999999px;
    --tw-border-opacity: 1;
    border: 2px solid rgba(209, 213, 219, var(--tw-border-opacity));

    svg {
      width: 1.4rem;
      height: 1.4rem;
      fill: currentColor;
      display: block;
      vertical-align: middle;
    }
  }
  .textValue {
    margin-left: 1rem;
    font-size: 1.075rem;
    line-height: 1.25rem;
    color: #9ca3af;
  }
}

// section {
//   h3 {
//     font-size: 18px;
//     font-weight: 900;
//     line-height: 23px;
//     margin: 0;
//   }
//   p {
//     font-weight: 300;
//     font-size: 11px;
//     line-height: 15px;
//     margin: 2px 0;
//     color: var(--color-text-light);

//     &.pin {
//       &::before {
//         font-family: "Material Icons";
//         content: "\e5cc";
//         position: relative;
//         margin: 0 5px;
//       }
//     }
//   }
// }
</style>